// @ts-nocheck
import { LoadingButton } from '@mui/lab';
import { Alert, Box, Button, Card, Checkbox, Container, FormControlLabel, FormLabel, Grid, TextField, Typography } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { format } from 'date-fns';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import HeaderWithTitle from '../../components/HeaderWithTitle';
import { callPostApi, getDlCaptcha, uploadDocGetAttributes } from '../../configs/api';
import { routes } from '../../configs/routes';

import { ArrowForwardIos as ArrowForwardIosIcon } from '@mui/icons-material';
import ErrorIcon from '@mui/icons-material/Error';
import LightModeOutlinedIcon from '@mui/icons-material/LightModeOutlined';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';
import ConfirmationScreen from '../../components/ConfirmationScreen';
import WebcamCapture from '../../components/Webcam';
import theme from '../../configs/theme';
import { isValidDate } from '../../helpers';
import { callSnackbar, verificationProps } from '../../wrapper';

// importing from CDN
const DLInstructionsImage = "https://storage.googleapis.com/indisi-images-dev/DLInstructionsImage.svg"
const DLFailedImage = "https://storage.googleapis.com/indisi-images-dev/DLFailedImage.svg"

type dLDataType = {
    number?: string,
    dob?: Date | null,
    captcha?: string
}

enum DL_SCREENS {
    SelectVerificationType,
    fillDLDetails,
    uploadDLInstructions,
    uploadDL,
    uploadDLfailed,
    ShowConfirmation
}

export default function DrivingLicense({ onError, onSuccess, onBack, token, disclaimer, confirmationRequired, enableDlCaptcha, autoCaptureDocuments }: verificationProps) {
    const styles = {
        rootContainer: { padding: '12px', height: '85vh', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' },
        backgroundImage: { borderRadius: '16px', backgroundColor: '#F6F7FF', height: '131px', display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '20px 0 20px 0' },
        fieldLabel2: { fontSize: '12px', fontWeight: '600', lineHeight: '18px' },
        submitBtn: { width: '100%', backgroundColor: '#08315F', marginTop: '20px' },
    }

    const webCamRef = useRef<any>(null);
    const flipButtonRef = useRef<any>(null);

    const [image, setImage] = useState<string>('');
    const [screen, setScreen] = useState<DL_SCREENS>(DL_SCREENS.SelectVerificationType);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [checkedDisclaimer, setCheckedDisclaimer] = useState(false);
    const [dLData, setDLData] = useState<dLDataType>({
        number: '',
        dob: null,
        captcha: ''
    });
    const [confirmationData, setConfirmationData] = useState({});
    const [captchaImage, setCaptchaImage] = useState('')

    const captureImage = useCallback(() => {
        webCamRef.current?.click(); // base64

    }, [webCamRef]);

    const getCaptcha = async () => {
        const response = await getDlCaptcha(token)
        if (response?.captcha) {
            setCaptchaImage(response?.captcha)
        }
    }

    useEffect(() => {
        getCaptcha()
    }, [])

    const updateDLData = (data: dLDataType) => setDLData((prev) => ({ ...prev, ...data }))

    const validateDL = async () => {
        if (!dLData.dob || !dLData.number) {
            return;
        }

        try {
            setIsLoading(true);
            let body = {
                'dob': format(dLData.dob, 'dd-MM-yyyy'),
                'dlno': dLData.number,
                'digiLocker': false,
            }
            if(enableDlCaptcha){
                body.captchaText = dLData.captcha
            }
            const res = await callPostApi(routes.drivingLicense, "?backendConfirmationRequired=true",
                body,
                {},
                token);

            if (res?.data?.code === 0) {
                if (!confirmationRequired) {
                    submitVerification()
                    return
                }
                setConfirmationData(res?.data?.data ?? {});
                setScreen(DL_SCREENS.ShowConfirmation)
            }
        } catch (error) {
        } finally {
            setIsLoading(false);
        }
    }

    const uploadImage = async () => {
        console.log("enableDlCaptcha", enableDlCaptcha)
        if(enableDlCaptcha){
            if(dLData.captcha?.length == 0){
                callSnackbar("error", "Please enter captcha");
                return
            }
        }
        if (!image) {
            return;
        }

        try {
            setIsLoading(true)
            let query = `?backendConfirmationRequired=true`
            if(enableDlCaptcha){
                query = query + `&captchaText=${dLData.captcha}`
            }
            const res = await uploadDocGetAttributes(routes.uploadDL, query, [image], token)
            if (res?.data?.code === 0) {
                setConfirmationData(res?.data?.data);
                setScreen(DL_SCREENS.ShowConfirmation);
            }
        } catch (error) {

        } finally {
            setIsLoading(false)
        }
    }

    const submitVerification = async () => {
        try {
            setIsLoading(true);
            const res = await callPostApi(routes.submitDlConfirmation, "", { "verificationName": "dl" }, {}, token);
            if (res?.data?.code === 0) {
                onSuccess('drivingLicense', true, null)
            } else {
                onError('drivingLicense', false)
            }
        } catch (error) {

        } finally {
            setIsLoading(false);
        }
    }

    const handleTopBackClick = useCallback(() => {
        setImage((prev) => '');
        setDLData((data) => ({
            number: '',
            dob: null,
        }));

        if ([DL_SCREENS.uploadDLInstructions, DL_SCREENS.fillDLDetails, DL_SCREENS.uploadDLfailed].includes(screen)) {
            setScreen((prev) => DL_SCREENS.SelectVerificationType);
        } else if (screen === DL_SCREENS.uploadDL) {
            setScreen((prev) => DL_SCREENS.uploadDLInstructions);
        } else {
            onBack();
        }
    }, [screen]);

    return (
        <Container maxWidth="sm" sx={styles.rootContainer}>
            <HeaderWithTitle
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    flexWrap: 'nowrap',
                    height: '100%',
                    margin: 'auto',
                }}
                isBackButtonDisabled={isLoading}
                title={'Verify Driving License'}
                onBack={handleTopBackClick}
            >
                <Box sx={{ width: '100%' }}>

                    {screen === DL_SCREENS.SelectVerificationType &&
                        <Box>
                            <Box sx={{ marginBottom: '12px' }}>
                                <Typography variant="body1" color="initial">
                                    Choose one of the following option to verify
                                </Typography>
                            </Box>


                            <Grid component={Card}
                                onClick={() => setScreen(DL_SCREENS.fillDLDetails)}
                                container
                                direction="row"
                                justifyContent="space-between"
                                alignItems="center"
                                sx={{ padding: '20px', cursor: 'pointer' }}>
                                <Grid sx={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
                                    <Typography sx={{ fontSize: '14px', lineHeight: '19px', fontWeight: 600, color: '#515151' }} >
                                        Type Details
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Typography sx={{ margin: '10px 0', textAlign: 'center', width: '100%' }} variant='body1'> or </Typography>

                            <Grid component={Card}
                                onClick={() => setScreen(DL_SCREENS.uploadDLInstructions)}
                                container
                                direction="row"
                                justifyContent="space-between"
                                alignItems="center"
                                sx={{ padding: '20px', cursor: 'pointer' }}>
                                <Grid sx={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
                                    <Typography sx={{ fontSize: '14px', lineHeight: '19px', fontWeight: 600, color: '#515151' }} >
                                        Upload Picture
                                    </Typography>
                                </Grid>
                            </Grid>

                        </Box>
                    }

                    <TabContext value={screen}>
                        <TabPanel value={DL_SCREENS.fillDLDetails} sx={{ padding: 0 }}>
                            <>
                                <Box sx={styles.backgroundImage}>
                                    <img src='https://storage.googleapis.com/indisi-images-dev/94c5da06c6a5d9b4467f7949a3a8770f59d57bb1d207c3df5e708e873798cbd9'
                                        style={{ width: '70px', height: 'auto' }}
                                        alt="aadhaar background"
                                    />
                                </Box>
                                <FormLabel htmlFor='dl' sx={styles.fieldLabel2}>
                                    Driving License Number
                                </FormLabel>
                                <Box>
                                    <TextField
                                        required
                                        id="dl"
                                        sx={{ width: '100%' }}
                                        value={dLData.number}
                                        disabled={isLoading}
                                        onChange={(e) => updateDLData({ number: e.target.value })}
                                    />
                                </Box>
                                <Box sx={{ width: '100%' }}>
                                    <FormLabel sx={styles.fieldLabel2}>
                                        Date of birth
                                    </FormLabel>
                                    <DatePicker
                                        disabled={isLoading}
                                        format='dd-MM-yyyy'
                                        value={dLData.dob}
                                        onChange={(dob) => updateDLData({ dob })}
                                        sx={{ width: '100%' }}
                                    />
                                </Box>
                                {enableDlCaptcha && <Box>
                                    <Grid container direction="row" alignItems="center" spacing={0}>
                                        <img src={`data:image/jpeg;base64,${captchaImage}`} alt="Captcha" />
                                        <TextField
                                            required
                                            id="captcha"
                                            sx={{ width: '100%' }}
                                            value={dLData.captcha}
                                            disabled={isLoading}
                                            placeholder='Enter Captcha'
                                            onChange={(e) => updateDLData({ captcha: e.target.value })}
                                        />
                                    </Grid>
                                </Box>}
                            </>
                        </TabPanel>
                        <TabPanel value={DL_SCREENS.uploadDLInstructions} sx={{ padding: 0 }}>
                            <>
                                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <img style={{ alignItems: 'center', maxHeight: '230px' }} src={DLInstructionsImage} alt="driving license instructions" />
                                </Box>
                                <Box>
                                    <Box sx={{ display: 'flex', justifyContent: 'center', marginBottom: '8px' }}>
                                        <Typography variant='h6' sx={{ lineHeight: '32px' }}>
                                            How to scan
                                        </Typography>
                                    </Box>
                                    <Typography variant='body2' color="grey1.main" sx={{ margin: 0 }}>
                                        Position your driving license in the frame and click capture to scan it for you.
                                    </Typography>
                                </Box>
                                <Box>
                                    <Typography color="secondary2.main" sx={{ margin: '6px 0px', display: 'block', fontSize: '12px', fontWeight: 600, lineHeight: '22px' }}>
                                        Tips:
                                    </Typography>
                                    <Box sx={{ marginBottom: '20px' }}>
                                        <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px', marginBottom: '3px' }}>
                                            <LightModeOutlinedIcon sx={{ fontSize: '12px' }} />
                                            <Typography color="grey1.main" sx={{ fontSize: '11px', lineHeight: '22px', margin: '0px' }}>
                                                Find an area with good lighting
                                            </Typography>
                                        </Box>
                                        <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                            <RemoveRedEyeOutlinedIcon sx={{ fontSize: '12px' }} />
                                            <Typography color="grey1.main" sx={{ fontSize: '11px', lineHeight: '22px', margin: '0px' }}>The License Number and Date Of Birth should be clearly visible</Typography>
                                        </Box>
                                    </Box>
                                </Box>
                            </>
                        </TabPanel>
                        <TabPanel value={DL_SCREENS.uploadDLfailed} sx={{ padding: 0 }}>
                            <>
                                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '70px 0 80px' }}>
                                    <img style={{ alignItems: 'center' }} src={DLFailedImage} alt="driving license instructions" />
                                </Box>

                                <Alert
                                    severity='error'
                                    sx={{
                                        backgroundColor: theme.palette.error.light,
                                        borderRadius: '8px',
                                        color: '#CE5945',
                                        fontSize: '13px',
                                        lineHeight: '20px',
                                        padding: '10px',
                                        '& .MuiAlert-message': {
                                            padding: 0
                                        },
                                        '& .MuiAlert-icon': {
                                            padding: 0
                                        }

                                    }}
                                    icon={<ErrorIcon sx={{ fontSize: '18px' }} />}
                                >
                                    The data cannot be retrieved from your Driving License. Please enter the details manually
                                </Alert>

                                <Button onClick={() => setScreen(DL_SCREENS.fillDLDetails)} sx={{ width: '100%' }}>Enter Data manually</Button>
                            </>
                        </TabPanel>
                        <TabPanel value={DL_SCREENS.uploadDL} sx={{ padding: 0 }}>
                            {image ? <img
                                src={image}
                                alt="captured"
                                style={{
                                    width: '100%',
                                }} /> :

                                <WebcamCapture
                                    setImage={setImage}
                                    buttonRef={webCamRef}
                                    flipButtonRef={flipButtonRef}
                                    aspectRatio={1.3}
                                    autoCaptureDocuments={autoCaptureDocuments}
                                />}
                            {enableDlCaptcha && <Box>
                                <Grid container direction="row" alignItems="center" spacing={0}>
                                    <img src={`data:image/jpeg;base64,${captchaImage}`} alt="Captcha" />
                                    <TextField
                                        required
                                        id="captcha"
                                        sx={{ width: '100%' }}
                                        value={dLData.captcha}
                                        disabled={isLoading}
                                        placeholder='Enter Captcha'
                                        onChange={(e) => updateDLData({ captcha: e.target.value })}
                                    />
                                </Grid>
                            </Box>}
                        </TabPanel>
                        <TabPanel value={DL_SCREENS.ShowConfirmation} sx={{ padding: 0 }}>
                            <ConfirmationScreen data={confirmationData} />
                        </TabPanel>
                    </TabContext>
                </Box>
            </HeaderWithTitle>

            <Box>
                {!([DL_SCREENS.SelectVerificationType, DL_SCREENS.uploadDLfailed].includes(screen)) &&
                    <FormControlLabel
                        required
                        control={<Checkbox
                            checked={checkedDisclaimer}
                            disabled={isLoading}
                            onChange={(e) => setCheckedDisclaimer(e.target.checked)} />}
                        label={<span style={{ fontSize: '12px', lineHeight: '16px' }}          >
                            {disclaimer}
                        </span>}
                    />
                }

                {screen === DL_SCREENS.fillDLDetails &&
                    <Box sx={{ display: 'flex', gap: '20px' }}>
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={() => setScreen(DL_SCREENS.SelectVerificationType)}
                            disabled={isLoading}
                            sx={{ width: '100%', marginTop: '20px' }}
                        >
                            Back
                        </Button>
                        <LoadingButton
                            color="primary"
                            variant="contained"
                            loading={isLoading}
                            disabled={!checkedDisclaimer || !dLData.number || !dLData.dob || isValidDate(dLData.dob)}
                            onClick={validateDL}
                            sx={styles.submitBtn}
                        >
                            Verify
                        </LoadingButton>
                    </Box>
                }

                {screen === DL_SCREENS.uploadDLInstructions &&
                    <Button disabled={!checkedDisclaimer} onClick={() => setScreen(DL_SCREENS.uploadDL)} variant="contained" color="primary" fullWidth>
                        Start Scanning <ArrowForwardIosIcon />
                    </Button>
                }


                {screen === DL_SCREENS.uploadDL &&
                    <Box sx={{ display: 'flex', gap: '20px' }}>
                        <Button
                            color="primary"
                            variant="contained"
                            sx={{ width: '100%', marginTop: '20px' }}
                            disabled={isLoading}
                            onClick={() => image ? setImage('') : setScreen(DL_SCREENS.SelectVerificationType)}
                        >
                            {image ? 'Retake' : 'Back'}
                        </Button>
                        <LoadingButton
                            disabled={!checkedDisclaimer}
                            loading={isLoading}
                            onClick={() => !!image ? uploadImage() : captureImage()}
                            variant="contained"
                            color="primary"
                            sx={{ width: '100%', marginTop: '20px' }}
                        >
                            {!!image ? 'Proceed' : 'Capture'}
                        </LoadingButton>
                    </Box>
                }

                {screen === DL_SCREENS.ShowConfirmation &&
                    <Box sx={{ display: 'flex', gap: '20px' }}>
                        <Button
                            color="primary"
                            variant="contained"
                            sx={{ width: '100%', marginTop: '20px' }}
                            disabled={isLoading}
                            onClick={() => { setImage(''); setDLData({ dob: null, number: '' }); onBack(); }}
                        >
                            Cancel
                        </Button>
                        <LoadingButton
                            disabled={!checkedDisclaimer}
                            loading={isLoading}
                            onClick={submitVerification}
                            variant="contained"
                            color="primary"
                            sx={{ width: '100%', marginTop: '20px' }}
                        >
                            Continue
                        </LoadingButton>
                    </Box>
                }
            </Box>
        </Container >
    );
}