// @ts-nocheck
import { ThemeProvider } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { SnackbarProvider } from "notistack";
import React from 'react';
import theme from "./configs/theme";
import Aadhaar from "./containers/aadhaar";
import DrivingLicence from "./containers/drivingLicense/index";
import Home from './containers/home';
import JapaneseResidentCard from "./containers/japaneseResidentCard";
import MexicanIdCard from "./containers/mexicanIdCard";
import Passport from "./containers/passport";

import { useEffect, useRef } from 'react';

import { enqueueSnackbar } from 'notistack';
import { createInstances } from "./configs/axios";
import { injectCss } from "./configs/css";
import { setEndpoint } from "./configs/routes";
import FaceLiveness from "./containers/liveness";
declare global {
    interface Window {
        snackbar?: any;
    }
}

export type verificationProps = {
    onError: (a: string, b: any) => void,
    onSuccess: (currentVerificationCompleted: string, response: any, verificationsCompleted: any) => void,
    onBack: () => void,
    token: string;
    disclaimer: string;
    environment: string;
    confirmationRequired: boolean;
    enableDlCaptcha: boolean;
    passportSkipDisclaimer: string;
    autoCaptureDocuments: boolean;
}

export type verificationsListData = {
    vName: string,
    logo: string,
    logoBg: string,
    link: string,
    key: string,
    component: (props: verificationProps) => JSX.Element,
}

const homeData: verificationsListData[] = [
    {
        vName: 'Aadhaar',
        logo: 'https://storage.googleapis.com/indisi-images-dev/abe4712eddf2d5938161988f90a61d818bb557fd81aa5ddeddf23ace5430ae3e',
        logoBg: '#FFEFEF',
        link: '/aadhaar',
        key: 'aadhaar',
        component: (props: any) => <Aadhaar {...props} />
    },
    {
        vName: 'Driving License',
        logo: 'https://storage.googleapis.com/indisi-images-dev/fff800069ed5e608986db4b3eda0c7dc9eedb2ee51703078eda58757c18ee321',
        logoBg: '#FFEFDC',
        link: '/driving-licence',
        key: 'drivingLicense',
        component: (props: any) => <DrivingLicence {...props} />
    },
    {
        vName: 'Passport',
        logo: 'https://storage.googleapis.com/indisi-images-dev/fc77b3e6410e2998ad742fd9a4e9572a32998f6e5d96d5376e021cbf53845666',
        logoBg: '#F4FCFD',
        link: '/passport',
        key: 'passport',
        component: (props: any) => <Passport {...props} />
    },
    {
        vName: 'Japanese Resident Card',
        logo: 'https://storage.googleapis.com/indisi-wallet-app/Passport.png',
        logoBg: '#F4FCFD',
        link: '/japanese-resident-card',
        key: 'japanese-resident-card',
        component: (props: any) => <JapaneseResidentCard {...props} />
    },
    {
        vName: 'Mexican ID Card',
        logo: 'https://storage.googleapis.com/indisi-wallet-app/mexican-id-icon.svg',
        logoBg: '#F4FCFD',
        link: '/mexican-id-card',
        key: 'mexican-id-card',
        component: (props: any) => <MexicanIdCard {...props} />
    },
    {
        vName: 'Liveness',
        logo: 'https://storage.googleapis.com/indisi-images-dev/fc77b3e6410e2998ad742fd9a4e9572a32998f6e5d96d5376e021cbf53845666',
        logoBg: '#F4FCFD',
        link: '/liveness',
        key: 'liveness',
        component: (props: any) => <FaceLiveness {...props} />,
        hidden: true
    },
]

export const callSnackbar = (variant: any, msg: string) => {
    if (msg) {
        enqueueSnackbar(
            msg?.slice(0, 1).toUpperCase().concat(msg?.slice(1)),
            {
                variant,
                style: { overflowWrap: 'anywhere', zIndex: '9999999 !important' },
                className: 'snackbar',
                anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
                autoHideDuration: 3000
            }
        );
    }
};
export default function Wrapper({
    onError = () => { },
    onSuccess = (...args: any) => { console.log(args) },
    verifications = ['liveness', 'aadhaar', 'passport', 'drivingLicense', 'japanese-resident-card', 'mexican-id-card'],
    token = '',
    disclaimer = 'I provide my consent to share my details',
    passportSkipDisclaimer = 'I hereby confirm my passport does not contain any identity-related information in the back page cover.',
    environment = 'development',
    confirmationRequired = false,
    enableDlCaptcha = false,
    autoCaptureDocuments = false
}) {
    console.log("passportSkipDisclaimerwapper", passportSkipDisclaimer)
    const isMounted = useRef(false);

    if (!isMounted.current) {


    }

    useEffect(() => {
        setEndpoint(environment)
        createInstances()
        injectCss()
        // Initial render has been completed
        // make the flag true
        isMounted.current = true;
    }, []);

    return (
        <SnackbarProvider maxSnack={3}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <ThemeProvider theme={theme}>
                    <div
                        id="main-container-client-verification-sdk"
                    >
                        <Home
                            token={token}
                            homeData={homeData.filter(x => verifications.includes(x.key))}
                            verifications={verifications}
                            onError={onError}
                            onSuccess={onSuccess}
                            disclaimer={disclaimer}
                            environment={environment}
                            enableDlCaptcha={enableDlCaptcha}
                            confirmationRequired={confirmationRequired}
                            passportSkipDisclaimer={passportSkipDisclaimer}
                            autoCaptureDocuments={autoCaptureDocuments}
                        /></div>
                </ThemeProvider>
            </LocalizationProvider>
        </SnackbarProvider>
    )
}
