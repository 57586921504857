import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Container, Typography, FormControlLabel, Checkbox } from '@mui/material';
import React, { useCallback, useRef, useState } from 'react';
import HeaderWithTitle from '../../components/HeaderWithTitle';
import WebcamCapture from '../../components/Webcam';
import { callPostApi, uploadDocGetAttributes } from '../../configs/api';
import { routes } from '../../configs/routes';
import { MAX_WIDTH } from '../../helpers';
import { verificationProps } from '../../wrapper';
import PassportInstructions from './Instructions';
import ConfirmationScreen from '../../components/ConfirmationScreen';

enum RESIDENT_CARD_SCREENS {
  InstructionsFront,
  CaptureFront,
  InstructionsBack,
  CaptureBack,
  ShowConfirmation,
}

type residentCardImages = {
  front: string;
  back: string;
}

export default function JapaneseResidentCard({ onError, onSuccess, onBack, token, disclaimer, confirmationRequired, autoCaptureDocuments }: verificationProps) {

  const [screen, setScreen] = useState(RESIDENT_CARD_SCREENS.InstructionsFront);
  const [loading, setLoading] = useState(false);
  const [capturedImages, setCapturedImages] = useState<residentCardImages>({
    front: '',
    back: '',
  });
  const [checkedDisclaimer, setCheckedDisclaimer] = useState(false);
  const [confirmationData, setConfirmationData] = useState({});

  const webCamRef = useRef<any>(null);
  const flipButtonRef = useRef<any>(null);
  const captureImage = useCallback(() => {
    webCamRef.current?.click(); // base64

  }, [webCamRef]);

  const setImage = (isFront: boolean, image: string) => setCapturedImages((prev) => ({
    ...prev,
    [isFront ? 'front' : 'back']: image,
  }));

  const handleBackClick = () => {
    switch (screen) {
      case RESIDENT_CARD_SCREENS.InstructionsFront:
        onBack();
        break;

      case RESIDENT_CARD_SCREENS.CaptureFront:
        setImage(true, '');
        setScreen(RESIDENT_CARD_SCREENS.InstructionsFront);
        break;

      case RESIDENT_CARD_SCREENS.ShowConfirmation:
        setImage(true, '');
        setImage(false, '');
        onBack();
        break;

    }
  }

  const handleContinueClick = () => {
    switch (screen) {
      case RESIDENT_CARD_SCREENS.InstructionsFront:
        setScreen(RESIDENT_CARD_SCREENS.CaptureFront);
        break;

      case RESIDENT_CARD_SCREENS.CaptureFront:
        if (capturedImages.front) {
          handleImagesSubmit();
        } else {
          captureImage();
        }
        break;

      case RESIDENT_CARD_SCREENS.ShowConfirmation:
        submitVerification();
        break;

      default:
        return;
    }
  }

  const handleImagesSubmit = async () => {
    if (!capturedImages.front) {
      return;
    }

    try {
      setLoading(true);
      const res = await uploadDocGetAttributes(routes.jpResidentCard, "?backendConfirmationRequired=true", [capturedImages.front], token);
      if (res?.data?.code === 0) {
        if(!confirmationRequired){
          submitVerification()
          return
        }
        setConfirmationData(res?.data?.data);
        setScreen(RESIDENT_CARD_SCREENS.ShowConfirmation);
      }
    } catch (error) {
      
    } finally {
      setLoading(false)
    }
  }

  const submitVerification = async () => {
    try {
      setLoading(true);
      const res = await callPostApi(routes.submitJpResidentCardConfirmation, "", { "verificationName": "japanese-resident-card" }, {}, token);

      if (res?.data?.code === 0) {
        onSuccess('japanese-resident-card', true, null)
      } else {
        onError('japanese-resident-card', false)
      }
    } catch (error) {

    } finally {
      setLoading(false);
    }
  }

  return (
    <Container maxWidth="sm" sx={{ padding: '12px', height: '85vh', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
      <HeaderWithTitle
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'center',
          flexWrap: 'nowrap',
          height: '100%',
          margin: 'auto',
          overflowY: 'auto',
        }}
        title={'Verify Japanese Resident Card'}
        onBack={handleBackClick}
        isBackButtonDisabled={loading}
      >

        {screen === RESIDENT_CARD_SCREENS.InstructionsFront &&
          <PassportInstructions isFront={true} />
        }

        {screen === RESIDENT_CARD_SCREENS.CaptureFront &&
          <>
            <Box>
              <Typography color="initial" sx={{ fontSize: '14px', fontWeight: '600', lineHeight: '22px', color: '#404052', margin: 0 }}>
                Please scan your card
              </Typography>
            </Box>
            <Box sx={{
              display: 'flex',
              justifyContent: 'center',
              margin: 'auto',
              width: '100%', 
              maxWidth: `${MAX_WIDTH}px`,
              backgroundColor: '#444'
            }}>



              {capturedImages.front ? <img
                src={capturedImages.front}
                alt="captured"
                style={{
                  width: '100%',
                  margin: '1em auto'
                }} /> :

                <WebcamCapture
                  setImage={(e: any) => {
                    setImage(true, e);
                  }}
                  buttonRef={webCamRef}
                  flipButtonRef={flipButtonRef}
                  aspectRatio={1.3}
                />}


            </Box>
          </>
        }

        {screen === RESIDENT_CARD_SCREENS.InstructionsBack &&
          <PassportInstructions isFront={false} />
        }

        {screen === RESIDENT_CARD_SCREENS.CaptureBack &&
          <>
            <Box>
              <Typography color="initial" sx={{ fontSize: '14px', fontWeight: '600', lineHeight: '22px', color: '#404052', margin: 0 }}>
                Please scan the back page of your card
              </Typography>
            </Box>
            <Box sx={{
              display: 'flex',
              justifyContent: 'center',
              margin: 'auto',
              width: '100%', maxWidth: `${MAX_WIDTH}px`,
              backgroundColor: '#444'
            }}>

              {capturedImages.back ? <img
                src={capturedImages.back}
                alt="captured"
                style={{
                  width: '100%',
                  margin: '1em auto'
                }} /> :

                <WebcamCapture
                  setImage={(e: any) => {
                    setImage(false, e);
                  }}
                  buttonRef={webCamRef}
                  flipButtonRef={flipButtonRef}
                  aspectRatio={1.3}
                  autoCaptureDocuments={autoCaptureDocuments}
                />}

            </Box>
          </>
        }

        {screen === RESIDENT_CARD_SCREENS.ShowConfirmation &&
          <ConfirmationScreen data={confirmationData} />
        }

      </HeaderWithTitle>

      <Box>
        <FormControlLabel
          required
          control={<Checkbox
            checked={checkedDisclaimer}
            disabled={loading}
            onChange={(e) => setCheckedDisclaimer(e.target.checked)}
          />}
          label={<span style={{ fontSize: '12px', lineHeight: '16px' }}          >
            {disclaimer}
          </span>}
        />

        <Box sx={{ display: 'flex', justifyContent: 'space-evenly', gap: '20px' }}>
          {screen === RESIDENT_CARD_SCREENS.InstructionsFront &&

            <Button
              disabled={!checkedDisclaimer}
              onClick={handleContinueClick}
              variant="contained"
              color="primary"
              sx={{ width: '100%', marginTop: '20px' }}
            >
              Start Scanning <ArrowForwardIosIcon />
            </Button>
          }

          {screen === RESIDENT_CARD_SCREENS.CaptureFront &&
            <>
              <Button
                onClick={capturedImages.front ? (() => setImage(true, '')) : handleBackClick}
                variant="contained"
                color="primary"
                disabled={loading}
                sx={{ width: '100%', marginTop: '20px' }}
              >
                {capturedImages.front ? 'Retake' : 'Back'}
              </Button>
              <LoadingButton
                loading={loading}
                disabled={!checkedDisclaimer}
                onClick={handleContinueClick}
                variant="contained"
                color="primary"
                sx={{ width: '100%', marginTop: '20px' }}
              >
                {capturedImages.front ? 'Proceed' : 'Capture'}
              </LoadingButton>
            </>
          }

          {screen === RESIDENT_CARD_SCREENS.InstructionsBack &&
            <>
              <Button
                onClick={handleBackClick}
                variant="contained"
                color="primary"
                sx={{ width: '100%', marginTop: '20px' }}
              >
                Back
              </Button>
              <Button
                disabled={!checkedDisclaimer}
                onClick={handleContinueClick}
                variant="contained"
                color="primary"
                sx={{ width: '100%', marginTop: '20px' }}
              >
                Continue
              </Button>
            </>
          }

          {screen === RESIDENT_CARD_SCREENS.CaptureBack &&
            <>
              <Button
                disabled={loading}
                onClick={capturedImages.back ? (() => setImage(false, '')) : handleBackClick}
                variant="contained"
                color="primary"
                sx={{ width: '100%', marginTop: '20px' }}
              >
                {capturedImages.back ? 'Retake' : 'Back'}
              </Button>

              <LoadingButton
                loading={loading}
                disabled={!checkedDisclaimer}
                onClick={handleContinueClick} variant="contained" color="primary" sx={{ width: '100%', marginTop: '20px' }}>
                {capturedImages.back ? 'Proceed' : 'Capture Back'}
              </LoadingButton>
            </>
          }

          {screen === RESIDENT_CARD_SCREENS.ShowConfirmation &&
            <>
              <Button
                disabled={loading}
                onClick={handleBackClick}
                variant="contained"
                color="primary"
                sx={{ width: '100%', marginTop: '20px' }}
              >
                Cancel
              </Button>

              <LoadingButton
                loading={loading}
                disabled={!checkedDisclaimer}
                onClick={handleContinueClick} 
                variant="contained" 
                color="primary" 
                sx={{ width: '100%', marginTop: '20px' }}>
                Continue
              </LoadingButton>
            </>
          }
        </Box>
      </Box>
    </Container >
  )
}