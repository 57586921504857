// @ts-nocheck
import { CancelOutlined, CheckCircleOutlined } from '@mui/icons-material';
import { Box, Grid, ListItemButton, Typography, useTheme } from '@mui/material';
import Container from '@mui/material/Container';
import React, { useEffect, useState } from 'react';
import { verificationsListData } from '../../wrapper';
interface homeDataProps {
    token: string;
    homeData: verificationsListData[];
    onError: (a: string, b: any) => void;
    onSuccess: (currentVerificationCompleted: string, response: any, verificationsCompleted: any) => void;
    disclaimer: string;
    verifications: string[];
    confirmationRequired: boolean;
    enableDlCaptcha: boolean;
    passportSkipDisclaimer: string;
    autoCaptureDocuments: boolean;
}

enum HOME_PAGE_SCREEN {
    main,
    verificationPage
}


export default function Home({ homeData, onError, onSuccess, token, disclaimer, verifications, environment, confirmationRequired, enableDlCaptcha, passportSkipDisclaimer, autoCaptureDocuments }: homeDataProps) {
    const theme = useTheme()
    const [selectedVerification, setSelectedVerification] = useState<verificationsListData | null>(null);
    const [completedVerifications, setCompletedVerifications] = useState<any>([]);
    const [livenessFailed, setLivenessFailed] = useState<boolean>(false)
    const [isTheProcessOver, setIsTheProcessOver] = useState<boolean>(false)

    useEffect(() => {
        const filteredCompletedVerifications = completedVerifications.filter(x => x !== 'liveness');
        if (filteredCompletedVerifications.length > 0) {

            const lvns = verifications.includes('liveness')
            if (lvns) {
                if (completedVerifications.includes('liveness')) {
                    setIsTheProcessOver(true);
                } else {
                    setIsTheProcessOver(false);
                }
            } else {
                setIsTheProcessOver(true)
            }
        } else {
            setIsTheProcessOver(false)
        }
    }, [completedVerifications, verifications])

    const renderStatus = (key) => {
        // completedVerifications.includes(data.key) ? <CheckCircleOutline
        //                             color="success" /> : null
        console.log("key", key)
        const lvnsRequired = homeData.find(v => v.key === 'liveness')
        if(completedVerifications?.includes(key)){
            if(lvnsRequired){
                if(livenessFailed){
                    return {
                        color: theme.palette.error.light,
                        icon: <CancelOutlined />
                    }
                }
                return {
                    color: theme.palette.success.light,
                    icon: <CheckCircleOutlined />
                }
            }
            return {
                color: theme.palette.success.light,
                icon: <CheckCircleOutlined />
            }
        }
        return {
            color: theme.palette.grey[200],
            icon: null
        }
    }
    console.log("complev",completedVerifications,  "failedv", livenessFailed)
    return selectedVerification ? selectedVerification.component({
        onError: (...args) => {
            if(selectedVerification.key == 'liveness'){
                const temp = [...completedVerifications, selectedVerification.key];
                setCompletedVerifications(temp)
                setLivenessFailed(true)
                onError(args[0], args[1], temp)
                setSelectedVerification(null)
            }
        }, 
        onSuccess: (...args) => {
            const temp = [...completedVerifications, selectedVerification.key];
            setCompletedVerifications(temp)
            const lvns = homeData.find(v => v.key === 'liveness')
            if (lvns && selectedVerification.key !== 'liveness') {
                setSelectedVerification(lvns)
            } else {
                onSuccess(args[0], args[1], temp)
                setSelectedVerification(null)
            }
        },
        onBack: () => setSelectedVerification(null),
        token,
        disclaimer,
        verifications,
        environment,
        confirmationRequired,
        enableDlCaptcha,
        passportSkipDisclaimer,
        autoCaptureDocuments
    }) : (
        <Container maxWidth="md" sx={{maxHeight: '85vh', overflowY: 'auto'}}>
            <Typography sx={{ marginTop: '24px' }} variant='h2'>
                Verification Gateway
            </Typography>

            <Typography sx={{ }} variant='body1'>
                You can verify your details using any <b>ONE</b> of the following methods
            </Typography>

            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '20px', margin: '20px 0' }}>
                {
                    homeData.map((data: any, i: number) => !data?.hidden ? (

                        <Grid
                            key={data.vName}
                            component={ListItemButton}
                            onClick={() => {
                                if (!isTheProcessOver && !completedVerifications.includes(data.key)) {
                                    setSelectedVerification(data)
                                }
                            }}
                            container
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                            sx={{
                                padding: '20px', cursor: 'pointer',
                                'borderRadius': '8px',
                                'boxShadow': '4px 4px 4px 3px #74707029',
                                backgroundColor: isTheProcessOver ? 
                                renderStatus(data?.key)?.color: '#ffffff'

                            }}>
                            <Grid
                                sx={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
                                <Box sx={{ width: '30px', height: '30px', backgroundColor: data.logoBg, borderRadius: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <img src={data.logo} alt={data.vName + ' logo'} style={{height: 25, width: 25}} />
                                </Box>

                                <Typography sx={{ fontSize: '14px', lineHeight: '19px', fontWeight: 600, color: '#515151', textWrap: 'wrap' }} >
                                    {data.vName}
                                </Typography>
                            </Grid>
                            <Grid item xs={2} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                {renderStatus(data?.key)?.icon}
                            </Grid>
                        </Grid>
                    ) : null)
                }
            </Box>
        </Container>
    )
}
