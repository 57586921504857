import { CameraAlt, CameraswitchOutlined } from '@mui/icons-material';
import { Button, Grid } from '@mui/material';
import * as cocoSsd from "@tensorflow-models/coco-ssd";
import * as tf from "@tensorflow/tfjs";
import React, { useEffect, useRef, useState } from 'react';
import Webcam from 'react-webcam';
import { MAX_WIDTH, MAX_WIDTH_HALF } from '../../helpers';
require('@tensorflow/tfjs-backend-cpu');
require('@tensorflow/tfjs-backend-webgl');

const WebcamCapture = ({ setImage, buttonRef, flipButtonRef, aspectRatio, autoCaptureDocuments }: any) => {
  const webcamRef = useRef<Webcam>(null);
  const [model, setModel] = useState<cocoSsd.ObjectDetection | null>(null);
  const [countdown, setCountdown] = useState<number>(3);

  const [facingMode, setFacingMode] = useState<'user' | 'environment'>('environment');
  const capture = React.useCallback(() => {
    if (webcamRef.current) {
      const imageSrc = webcamRef.current.getScreenshot();
      console.log('imageSrc', imageSrc);
      setImage(imageSrc);
    }
  }, [setImage]);

  // Set the backend and load the model
  useEffect(() => {
    const initialize = async () => {
      try {
        // Set the backend to WebGL (or fallback to CPU if WebGL is not available)
        await tf.setBackend("webgl");
        console.log("Backend set to:", tf.getBackend());

        // Load the COCO-SSD model
        const loadedModel = await cocoSsd.load();
        setModel(loadedModel);
        console.log("Model loaded successfully", loadedModel);
      } catch (error) {
        console.error("Error initializing TensorFlow.js:", error);
      }
    };
    if (autoCaptureDocuments) initialize();
  }, []);


  // Capture image when passport is detected
  useEffect(() => {
    if (!autoCaptureDocuments) return
    if (!model) return;
    let interval: any
    setTimeout(() => {
      interval = setInterval(async () => {
        if (webcamRef.current) {
          // Capture screenshot from the webcam
          const imageSrc = webcamRef?.current?.getScreenshot() ?? '';

          // Create an image element
          const img = new Image();
          img.src = imageSrc;

          img.onload = async () => {
            // Create a canvas element
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');

            if (!ctx) {
              console.error('Could not get canvas context');
              return;
            }

            // Rotate the image 90 degrees clockwise
            canvas.width = img.height; // Swap width and height for rotation
            canvas.height = img.width;

            // Translate and rotate the context
            ctx.translate(canvas.width / 2, canvas.height / 2);
            ctx.rotate((90 * Math.PI) / 180);
            ctx.translate(-img.width / 2, -img.height / 2);

            // Draw the rotated image on the canvas
            ctx.drawImage(img, 0, 0);

            // Convert the canvas to a data URL
            const rotatedImageSrc = canvas.toDataURL('image/jpeg');

            // Create a new image element for the rotated image
            const rotatedImg = new Image();
            rotatedImg.src = rotatedImageSrc;

            rotatedImg.onload = async () => {
              // Detect objects in the rotated image using the COCO-SSD model
              const predictions = await model.detect(rotatedImg);
              console.log('predictions', predictions);

              // Check if a passport is detected
              const isPassportDetected = predictions.some(
                (prediction) => ["book"]?.includes(prediction.class)
              );

              if (isPassportDetected) {
                // capture(); // Capture the image
                setImage(imageSrc); // Save the rotated image
                clearInterval(interval); // Stop capturing once the passport is detected
                console.log('Passport detected and image captured');
              }
            };
          };
        }
      }, 1000); // Check every second
    }, 2000)
    return () => {
      clearInterval(interval);
    }
  }, [model]);

  useEffect(() => {
    let countdownInterval: any;

    if (autoCaptureDocuments && countdown > 0) {
      countdownInterval = setInterval(() => {
        // Use a functional update to avoid stale state issues
        setCountdown((prevCountdown) => {
          if (prevCountdown === 1) {
            clearInterval(countdownInterval); // Clear the interval when countdown reaches 0
          }
          return prevCountdown - 1; // Decrement the countdown
        });
      }, 1000);
    }

    // Cleanup function to clear the interval
    return () => {
      if (countdownInterval) {
        clearInterval(countdownInterval);
      }
    };
  }, [autoCaptureDocuments, countdown]); // Re-run the effect when autoCaptureDocuments or countdown changes

  return (
    <Grid
      container
      direction="column"
      justifyContent="flex-start"
      alignItems="center"
      sx={{
        padding: '1em',
        width: '100%',
        maxWidth: `${MAX_WIDTH}px`,
        background: '#ffffff',
        height: '100%',
        margin: 'auto',
      }}
    >
      <div
        style={{
          width: '100%',
          maxWidth: `${MAX_WIDTH}px`,
          border: '3.5px dashed #FFFFFF',
          position: 'relative', // Make the container a positioning context
        }}
      >
        {/* Overlay countdown on the webcam feed */}
        {countdown > 0 && autoCaptureDocuments && (
          <h1
            style={{
              position: 'absolute', // Position the countdown absolutely
              top: '30%', // Center vertically
              left: '50%', // Center horizontally
              transform: 'translate(-50%, -50%)', // Center the text
              fontSize: '10rem', // Large font size
              color: 'white', // White text
              textShadow: '2px 2px 10px rgba(0, 0, 0, 0.5)', // Add shadow for better visibility
              zIndex: 1, // Ensure the countdown is above the webcam feed
            }}
          >
            {countdown}
          </h1>
        )}

        {/* Webcam feed */}
        <Webcam
          ref={webcamRef}
          videoConstraints={{
            facingMode: facingMode,
            aspectRatio: aspectRatio,
          }}
          screenshotFormat="image/jpeg"
          style={{ width: '100%', height: 'auto' }}
        />
      </div>

      <Button
        ref={buttonRef}
        variant="outlined"
        color="secondary"
        sx={{
          display: 'none',
          padding: '1em 2em',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '45%',
          maxWidth: MAX_WIDTH_HALF,
        }}
        onClick={capture}
      >
        <CameraAlt />
        &nbsp; Capture
      </Button>

      <Button
        ref={flipButtonRef}
        variant="outlined"
        color="primary"
        sx={{
          display: 'none',
          padding: '1em 2em',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          position: 'absolute',
          top: '5em',
          right: '2em',
          width: '45%',
          maxWidth: MAX_WIDTH_HALF,
        }}
        onClick={() =>
          setFacingMode((prev) => (prev === 'user' ? 'environment' : 'user'))
        }
      >
        <CameraswitchOutlined />
        &nbsp; Flip Camera
      </Button>
    </Grid>
  );
};

export default WebcamCapture;
